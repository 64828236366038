import { template as template_61652c5cd379463e8e6657b741344223 } from "@ember/template-compiler";
const SidebarSectionMessage = template_61652c5cd379463e8e6657b741344223(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
