import { template as template_2d82a2f57ab3468db44d9c99f0a7b564 } from "@ember/template-compiler";
const FKText = template_2d82a2f57ab3468db44d9c99f0a7b564(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
